import React from "react"
import {getTitleSvg, getPagesName} from "src/utils/exportFunctions"

const TitleSvg= (props) => {
    const pageName = getPagesName(props.pageId);
    const titleId = 'titleSvg_' + pageName;
    return (
        <img id={titleId} className={`
            titleSvg 
            ${props.className ? props.className : ''}
        `} src={getTitleSvg(props.pageId)} alt={pageName} />
    );
};

export default TitleSvg;