import React from "react"
import {BREAKPOINTS} from "src/utils/exportFunctions"

const StylesTitleSvg= (props) => {
    return (
        <style>{`
        img.titleSvg#titleSvg_services {
            width: 238px;
        }
        img.titleSvg#titleSvg_company {
            width: 234px;
        }
        img.titleSvg#titleSvg_access {
            width: 187px;
        }

        @media (min-width: ${BREAKPOINTS['md']}px) {
            img.titleSvg#titleSvg_services {
                width: 331px;
            }
            img.titleSvg#titleSvg_company {
                width: 327px;
            }
            img.titleSvg#titleSvg_access {
                width: 260px;
            }
        }
        @media (min-width: ${BREAKPOINTS['lg']}px) {
        }
        @media (min-width: ${BREAKPOINTS['xl']}px) {
            img.titleSvg#titleSvg_services {
                width: 400px;
            }
            img.titleSvg#titleSvg_company {
                width: 395px;
            }
            img.titleSvg#titleSvg_access {
                width: 314px;
            }
        }
        `}
        </style>
    );
};

export default StylesTitleSvg;