import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = (props) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 2560) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  //console.log(props.filename + '_pc.' + props.ext);
  //console.log(allImageSharp);
  if(props.isUsePicture === true) {
    return (
      <picture>
        <source
          srcSet={allImageSharp.nodes.find(n => n.fluid.originalName === props.filename + '_pc.' + props.ext).fluid.src}
          media="(min-width: 1280px)"
          width={props.sizes? (props.sizes.width.pc ? props.sizes.width.pc : 0) : 0}
          height={props.sizes? (props.sizes.height.pc ? props.sizes.height.pc : 0) : 0}
        />
        <source
          srcSet={allImageSharp.nodes.find(n => n.fluid.originalName === props.filename + '_tab.' + props.ext).fluid.src}
          media="(min-width: 768px)"
          width={props.sizes? (props.sizes.width.tab ? props.sizes.width.tab : 0) : 0}
          height={props.sizes? (props.sizes.height.tab ? props.sizes.height.tab : 0) : 0}
        />
        <source
          srcSet={allImageSharp.nodes.find(n => n.fluid.originalName === props.filename + '_sp.' + props.ext).fluid.src}
          media="(min-width: 320px)"
          width={props.sizes? (props.sizes.width.sp ? props.sizes.width.sp : 0) : 0}
          height={props.sizes? (props.sizes.height.sp ? props.sizes.height.sp : 0) : 0}
        />
        <img
          src={allImageSharp.nodes.find(n => n.fluid.originalName === props.filename + '_sp.' + props.ext).fluid.src}
          alt={props.alt}
          className={props.className}
          width={props.sizes? (props.sizes.width.sp ? props.sizes.width.sp : 0) : 0}
          height={props.sizes? (props.sizes.height.sp ? props.sizes.height.sp : 0) : 0}
        />
      </picture>
    );
  } else {
    return (
      <figure className={props.className} style={props.style}>
        <Img
          fluid={allImageSharp.nodes.find(n => n.fluid.originalName === props.filename).fluid}
          alt={props.alt}
        />
      </figure>
    );
  }
};

export default Image;