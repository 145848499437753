import React from "react"
import InViewMonitor from "react-inview-monitor"

export const AnimIn = ({children, delay, margin = "-20%"}) => (
    <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
        <FadeIn isActive={false} delay={delay}>{children}</FadeIn>
    </InViewMonitor>
);
export const AnimUp = ({children, delay, margin = "-20%"}) => (
    <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
        <FadeInUp isActive={false} delay={delay}>{children}</FadeInUp>
    </InViewMonitor>
);
export const AnimDown = ({children, delay, margin = "-20%"}) => (
    <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
        <FadeInDown isActive={false} delay={delay}>{children}</FadeInDown>
    </InViewMonitor>
);
export const AnimLeft = ({children, delay, margin = "-20%"}) => (
    <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
        <FadeInLeft isActive={false} delay={delay}>{children}</FadeInLeft>
    </InViewMonitor>
);
export const AnimRight = ({children, delay, margin = "-20%"}) => (
    <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
        <FadeInRight isActive={false} delay={delay}>{children}</FadeInRight>
    </InViewMonitor>
);
const getStyle = (isActive, tsfmBef, tsfmAftr, delay = 0) => {
    const opacity = isActive? 1 : 0;
    let styles = {
        'opacity': opacity,
        'transition': 'opacity 1s ease, transform 1s ease',
        'transitionDelay': delay + 'ms',
        'transform': isActive? tsfmAftr : tsfmBef
    };
    return styles;
};
const FadeIn = ({children, isActive, delay = 0}) => {
    return (
        <div style={getStyle(isActive, 'none', 'none', delay)}>{children}</div>
    );
};
const FadeInUp = ({children, isActive, delay = 0}) => {
    return (
        <div style={getStyle(isActive, 'translateY(30px)', 'translateY(0px)', delay)}>{children}</div>
    );
};
const FadeInDown = ({children, isActive, delay = 0}) => {
    return (
        <div style={getStyle(isActive, 'translateY(-30px)', 'translateY(0px)', delay)}>{children}</div>
    );
};
const FadeInLeft = ({children, isActive, delay = 0}) => {
    return (
        <div style={getStyle(isActive, 'translateX(-30px)', 'translateX(0px)', delay)}>{children}</div>
    );
};
const FadeInRight = ({children, isActive, delay = 0}) => {
    return (
        <div style={getStyle(isActive, 'translateX(30px)', 'translateX(0px)', delay)}>{children}</div>
    );
};